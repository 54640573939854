import { ApiBase } from "@/api/api-base";
import { LabelResponse } from "$/interfaces/ui-api/response/label-response";

class LabelsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "labels", name: "LabelsApi" });
  }

  public async get(): Promise<LabelResponse[]> {
    const labels = await this.client.get<LabelResponse[]>();
    return labels.data;
  }
}

export const Labels = new LabelsApi();
