import { PolicyItemType } from "../../policy-item-base";
import type { MatcherType } from "../match-type";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    scm = "scm",
    org = "org",
    project = "project",
    repo = "repo",
    branch = "branch",
    product = "product",
    label = "label"
  }
}

(PolicyConditionSubType as { scm: "scm" }).scm = "scm";
(PolicyConditionSubType as { org: "org" }).org = "org";
(PolicyConditionSubType as { project: "project" }).project = "project";
(PolicyConditionSubType as { repo: "repo" }).repo = "repo";
(PolicyConditionSubType as { branch: "branch" }).branch = "branch";
(PolicyConditionSubType as { product: "product" }).product = "product";
(PolicyConditionSubType as { label: "label" }).label = "label";

export interface PolicyConditionAsset extends PolicyCondition {
  sub:
    | PolicyConditionSubType.scm
    | PolicyConditionSubType.org
    | PolicyConditionSubType.project
    | PolicyConditionSubType.repo
    | PolicyConditionSubType.branch
    | PolicyConditionSubType.product
    | PolicyConditionSubType.target_branch
    | PolicyConditionSubType.label;

  matcherType?: MatcherType;
}

export const PolicyConditionAsset = {
  components: [
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.scm
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.org
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.project
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.repo
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.branch
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.label
    }
  ] as const
} as const;
