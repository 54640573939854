import Vue from "vue";
import { LabelResponse } from "$/interfaces/ui-api/response/label-response";
import { Labels as Api } from "@/api";

class LabelsState {
  public constructor() {
    Vue.observable(this);
  }

  private labels: Promise<LabelResponse[]> | null = null;
  public getLabels(refresh = false): Promise<LabelResponse[]> {
    if (refresh || this.labels === null) {
      this.labels = Api.get();
    }
    return this.labels;
  }
}

export const Labels = new LabelsState();
