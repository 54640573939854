import { ConfigFlags } from "@arnica-internal/configs";

export enum BusinessImportanceLabel {
  high = "high",
  medium = "medium",
  low = "low"
}

export const BusinessImportanceLabelPriorities = {
  low: 0,
  medium: 1,
  high: 2
} as const;
export type ResourceWithBusinessImportanceScore = { businessImportance: { score: number } };
export type ResourceWithBusinessImportanceLabel = { businessImportance: { label: BusinessImportanceLabel } };
export type ResourceWithBusinessImportance = ResourceWithBusinessImportanceScore & ResourceWithBusinessImportanceLabel;
export type BusinessImportance = ResourceWithBusinessImportance["businessImportance"];

export type BusinessImportanceWeights = ConfigFlags["businessImportance"]["weights"];
/**
 * Plan
 *
 * 1. create a table of business importance scores?
 * 2. during ingestion in the ingestor - add any data we can (numContributors, PRs etc?) - need an API / or write to S3 orgdatabucket?
 * 3. during ingestion in the dataplane - anything we need from neo4j (if we didn't during ingest) -  call an API to update the table or add to the S3 orgdatabucket?
 * 4. during detect job - get anything code related (hasIaC, hasCICD, hasCodeHandlingPII) - call an API to update the table or add to the S3 orgdatabucket?
 * 5. compute the score
 *    a. option 1: if we are using an API to update the table, compute the score on each data update as long as we have all fields (e.g. at least after 1 ingestion)
 *    b. option 2: or if we are writing to S3 orgdatabucket, compute the score after job complete (it's getting a bit large...)
 *
 * or...  have all the data in the all repos report and compute the score on the fly... we can easily calculate it in the UI and sort by it... it's a quick computation
 * or...  have the automatic score be part of the all repos report... (simplest solution) - and any overrides can be in a table
 */
export type BusinessImportanceScalarAttributes = Record<keyof BusinessImportanceWeights["scalar"], number>;
export type BusinessImportanceBooleanAttributes = Record<keyof BusinessImportanceWeights["boolean"], boolean>;
export type BusinessImportanceParams = BusinessImportanceScalarAttributes & BusinessImportanceBooleanAttributes;

export const defaultBusinessImportanceBooleanWeights: Record<keyof BusinessImportanceBooleanAttributes, number> = {
  iac: 5,
  cicd: 2,
  bp: 5
};

export const defaultBusinessImportanceScalarWeights: Record<keyof BusinessImportanceScalarAttributes, number> = {
  contributorCount: 1,
  prCount: 1
};

export const BASE_BUSINESS_IMPORTANCE_SCORE = 1;

/**
 * Returns the business importance of a resource (e.g. repo)
 * See: https://github.com/arnica-internal/arnica/issues/1364
 *
 * @param params see {@link BusinessImportanceParams}
 * @param booleanWeights see {@link BusinessImportanceBooleanAttributes} - if true, multiply the result by the weight
 * @param scalarWeights see {@link BusinessImportanceScalarAttributes} - multiply the result by the scalar * weight
 */
export function getBusinessImportanceScore(
  params: BusinessImportanceParams,
  booleanWeights: BusinessImportanceWeights["boolean"] = defaultBusinessImportanceBooleanWeights,
  scalarWeights: BusinessImportanceWeights["scalar"] = defaultBusinessImportanceScalarWeights
): number {
  let importance = BASE_BUSINESS_IMPORTANCE_SCORE;
  for (const booleanAttributeKey of Object.keys(booleanWeights)) {
    if (params[booleanAttributeKey]) {
      importance *= booleanWeights[booleanAttributeKey];
    }
  }
  for (const scalaAttributeKey of Object.keys(scalarWeights)) {
    importance *= scalarWeights[scalaAttributeKey] * (params[scalaAttributeKey] || 1);
  }

  return importance;
}

/**
 * Return items with business importance as high/medium/low
 * @param repoAndBusinessImportance see {@link ResourceWithBusinessImportanceScore} - adds businessImportanceLabel (inline)
 */
export function addBusinessImportance<T extends ResourceWithBusinessImportanceScore>(repoAndBusinessImportance: T[]): (T & ResourceWithBusinessImportance)[] {
  repoAndBusinessImportance.sort((a, b) => b.businessImportance.score - a.businessImportance.score);
  const repoScoresDescending = repoAndBusinessImportance
    .map((repo) => repo.businessImportance.score)
    .distinct()
    .sort((a, b) => b - a);

  const scoreLabels: Record<number, BusinessImportanceLabel> = {};
  const scoreCount = repoScoresDescending.length;
  if (scoreCount === 0) {
    return [];
  }

  // sort descending

  /*
  If the count of scores is bigger than 1000, return the 99th percentile
  If the count of scores is between 100 and 999, return the top 97th percentile
  If the count of scores is between 50 and 99, return the 95th percentile
  If the count of scores is between 30 and 49, return top 3.
  If the count of scores is lower than 30, return top 1.
 */

  const percentileIndex = (percentile: number) => Math.ceil((scoreCount * (100 - percentile)) / 100);
  const startIndices = {
    medium: -1,
    low: -1
  };

  if (scoreCount < 5) {
    startIndices.medium = 1;
    startIndices.low = 2;
  } else if (scoreCount < 30) {
    startIndices.medium = 1;
    startIndices.low = percentileIndex(75);
  } else if (scoreCount < 50) {
    startIndices.medium = 3;
    startIndices.low = percentileIndex(80);
  } else if (scoreCount < 100) {
    startIndices.medium = percentileIndex(95);
    startIndices.low = percentileIndex(80);
  } else if (scoreCount < 1000) {
    startIndices.medium = percentileIndex(97);
    startIndices.low = percentileIndex(80);
  } else {
    startIndices.medium = percentileIndex(99);
    startIndices.low = percentileIndex(80);
  }

  for (let i = 0; i < scoreCount; i++) {
    const score = repoScoresDescending[i];
    if (score === undefined) {
      throw new Error("score is undefined");
    }
    if (i < startIndices.medium) {
      scoreLabels[score] = BusinessImportanceLabel.high;
    } else if (i < startIndices.low) {
      scoreLabels[score] = BusinessImportanceLabel.medium;
    } else {
      scoreLabels[score] = BusinessImportanceLabel.low;
    }
  }

  const result = repoAndBusinessImportance as (T & ResourceWithBusinessImportance)[];
  for (const repo of result) {
    const scoreLabel = scoreLabels[repo.businessImportance.score];
    if (!scoreLabel) {
      throw new Error(`No score label for score ${repo.businessImportance.score}`);
    }
    repo.businessImportance.label = scoreLabel;
  }
  return result;
}
