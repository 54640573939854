
<div v-if="item.show" :key="item.title" :title="item.tooltip" class="component navigation-item tooltip-wrapper">
  <v-list-item :ripple="false" :to="item.link" link :inactive="root" :disabled="item.disabled">
    <v-list-item-content v-if="$state.Layout.drawer.mini">
      <v-icon :x-small="!root" disabled>{{ item.icon }}</v-icon>
    </v-list-item-content>
    <template v-if="root">
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-else>
      <v-list-item-content>
        <v-list-item-title>
          <v-icon x-small left>{{ item.icon }}</v-icon>
          {{ item.title }}
          <v-icon x-small v-if="item.frontIcon" :title="item.frontIcon.tooltip">{{ item.frontIcon.icon }}</v-icon>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-list-item>
  <template v-for="child in item.children">
    <navigation-item dense class="sub-menu-item" :key="child.title" :item="child" :root="false" />
  </template>
</div>
