import { adminPageRoutes } from "./admin/admin-routes";
import { inventoryPageRoutes } from "./inventory/inventory-routes";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { Scope } from "@/interfaces/scope";
import HomePage from "@/pages/home-page.vue";
import { risksPageRoutes } from "@/pages/risks/risks-routes";
import * as State from "@/state";
import * as Api from "@/api";
import { arnicaAdminRoutes } from "@/pages/arnica-admin-routes";

export const pageRoutes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
      if (!State.Layout.onboarding.orgId) {
        return next({ name: "onboarding" });
      }

      await Promise.allSettled([State.Integrations.init()]);

      if (State.Layout.onboarding.isDoneOnboarding) {
        return next();
      } else {
        return next({ name: "onboarding" });
      }
    },
    meta: {
      scopes() {
        return true;
      },
      // title: "Home",
      orgId: "optional",
      // icon: "mdi-home",
      navDrawer: false
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/pages/login-page.vue"),
    meta: {
      scopes() {
        return true;
      },
      title: "",
      appBar: false
    }
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import(/* webpackChunkName: "forbidden" */ "@/pages/forbidden-page.vue"),
    meta: {
      scopes() {
        return true;
      },
      title: "",
      appBar: false
    }
  },
  {
    path: "/logout",
    name: "logout",
    async beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
      await State.Auth.auth0SignOut();
      return next({ name: "login" });
    },
    meta: {
      scopes: [Scope.registered],
      title: "Sign Out",
      appBar: false
    }
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import(/* webpackChunkName: "onboarding" */ "@/pages/onboarding-page.vue"),
    meta: {
      scopes: [Scope.authenticated],
      orgId: "optional",
      title: "",
      sidebar: false,
      navDrawer: false
    }
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import(/* webpackChunkName: "loading" */ "@/pages/loading-page.vue"),
    meta: {
      scopes: [Scope.authenticated],
      title: "Loading...",
      icon: "mdi-progress-clock",
      sidebar: false
    }
  },
  ...risksPageRoutes,
  ...inventoryPageRoutes,
  {
    path: "/notifications",
    name: "notifications",
    component: () => import(/* webpackChunkName: "notifications" */ "@/pages/notifications-page.vue"),
    meta: {
      scopes: [Scope.authenticated],
      sidebar: false,
      title: "Notifications",
      icon: "mdi-bell"
    }
  },
  ...adminPageRoutes,
  {
    path: "/error",
    name: "error",
    component: () => import("@/pages/error-page.vue"),
    meta: {
      scopes() {
        return true;
      },
      title: "Error"
    }
  },
  {
    path: "/product-tour",
    name: "product-tour",
    redirect() {
      State.Demo.enable();
      throw new Error("Redirecting to demo");
    },
    meta: {
      scopes: [Scope.registered]
    }
  },
  {
    path: "/sfa",
    name: "sfa",
    component: () => import("@/pages/sfa/sfa.vue"),
    meta: {
      scopes: [Scope.arnica_admin]
    }
  },
  {
    path: "/download/:bucket/:key*",
    name: "download",
    async beforeEnter(to, _from, next) {
      const { bucket, key } = to.params;
      if (!bucket || !key) {
        void State.Popup.error("Invalid download link");
        return next(false);
      }
      const url = await Api.Storage.getSignedUrl(bucket, key).autoHandleError();
      if (!url) {
        return next(false);
      }

      window.open(url.href, "_blank");
      return next(false);
    },
    meta: {
      scopes: [Scope.registered]
    }
  },
  ...arnicaAdminRoutes
];
